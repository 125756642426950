
import React from "react"

import ProductBasicEditorPage from "../../components/negosyonow/controls/productbasiceditor"

const formFields = [
	[
		{"label":"Primary Image", "field": "nnproduct_image", "value": "", "input": "image", "mode": "readonly"},
		{"label":"Seller SKU", "field": "nnproduct_orgsku", "value": "", "input": "textbox", "mode": "readonly"},
		{"label":"Full Name / Display", "field": "nnproduct_fullname", "value": "", "input": "textbox", "mode": "readonly"},
		{"label":"Has Page", "field": "nnproduct_haspage", "value": "", "input": "hidden", "mode": "readonly"},
		{"label":"Webpage", "field": "nnproduct_actualurl", "value": "", "input": "url", "mode": "readonly", "urlprefix":"https://negosyonow.com/"},
		{"label":"Last Updated", "field": "nnproduct_lastupdate", "value": "", "input": "updatetime", "mode": "readonly"},
		{"label":"Active", "field": "nnproduct_active", "value": "", "input": "checkbox", "mode": "required"},
		{"label":"Price", "field": "nnproduct_price", "value": "", "input": "currency", "mode": "readonly"}
	]
];

const ProductpricingPage = ({location}) => {
	return <ProductBasicEditorPage
				location={location}
				formFields={formFields}
			/>
}


export default ProductpricingPage;
